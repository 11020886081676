import React from 'react'
import Layout from 'components/Layout'
import Link from 'components/Link'

const ProductPage = ({ location }) => (
  <Layout
    title="All the tools to unlock your data"
    description="Use UseData to connect to any data source (Redshift, BigQuery, MySQL, PostgreSQL, MongoDB and many others), query, visualize and share your data to make your company data driven."
    location={location}
  >
    <section className="section section-product-hero">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center">
            <h1 className="m-b-100 serif">Get actionable <u>insights</u> from your data</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <h3 className="m-t-0 feature_font">
              <span className="m-r-15 feature_icon"><i className="fa fa-bolt" aria-hidden="true"></i></span> Discover
            </h3>
            <h2 className="m-t-0">
              Answer questions by combining data from many sources
            </h2>
            <ul className="highlight-ul">
              <li>Query data from different data silos</li>
              <li>Combine results from multiple queries</li>
              <li>Analyze data using advanced ML/AI technologies</li>
              <li>Schedule auto-update for results your business rely on</li>
            </ul>
            <Link
              data-track
              data-track-location="product-hero"
              track-event="Clicked Check Integrations"
              to="/integrations"
              className="btn btn-secondary"
            >
              Check data sources
            </Link>
          </div>

          <div className="col-md-6">
            <div className="browser-container hidden-xs">
              <img
                src="/assets/images/elements/query.png"
                width="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section bg-soft-yellow">
      <div className="container">
        <div className="row">
          <div className="col-md-6 push-sm-down">
            <div className="dashboard-container hidden-xs">
              <img
                src="/assets/images/elements/dashboard.svg"
                width="100%"
              />
            </div>
          </div>

          <div className="col-md-6">
            <h3 className="m-t-0 feature_font text-right">
              <span className="m-r-15 feature_icon"><i className="fa fa-bar-chart" aria-hidden="true"></i></span> Demonstrate
            </h3>
            <h2 className="m-t-0 text-right">Visualize findings with customizable dashboards</h2>
            <ul className="highlight-right-ul">
              <li>
                Build different types of visualizations from query results
              </li>
              <li>
                Combine query results from multiple sources into a single dashboard
              </li>
              <li>
                Share your data-story with colleagues, other teams within your organization or external
                partners via secret URLs
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section className="section">
      <div className="container">
        <h3 className="m-t-0 feature_font text-center mb-xs">
          <span className="m-r-15 feature_icon"><i className="fa fa-paper-plane" aria-hidden="true"></i></span> Deliver
        </h3>
        <h2 className="m-t-0 text-center mb-md">
          Trigger actions accross systems and business units
        </h2>
        <div className="row text-center">
          <div className="col-md-4">
            <img src="/assets/images/elements/embedded_analytics.svg" width="64px" height="64px"/>
            <h2>Embeded Analytics</h2>
            <p>
              Deliver interactive reports that matter to your clients
            </p>
          </div>

          <div className="col-md-4">
            <img src="/assets/images/elements/icon-api.svg" width="64px" height="64px"/>
            <h2>API</h2>
            <p>
              Access data via API and extend its usability as you like.
            </p>
          </div>

          <div className="col-md-4">
            <img
              src="/assets/images/elements/icon-streams.svg"
              width="64px"
              height="64px"
            />
            <h2>Streams</h2>
            <p>
              Stream your data to the destinations of your choice.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className="section section--pt-sm bg-soft-blue">
      <div className="container">
        <h3 className="m-t-0 feature_font text-center mb-xs">
          <span className="m-r-15 feature_icon"><i className="fa fa-lock" aria-hidden="true"></i></span> Administrate
        </h3>
        <h2 className="m-t-0 text-center mb-md">
          Control data access within your organization
        </h2>
        <div className="row">
          <div className="col-sm-6">
            <div>
              <img src="/assets/images/elements/group.svg" width="64px" height="64px"/>
              <h2>Group Monitoring</h2>
              <p className="highlight-p">
                Restrict data <u>visibility</u> to certain groups only
              </p>
              <p className="highlight-p">
                Publish query results to allowed groups or departments in your organization
              </p>              
            </div>
          </div>

          <div className="col-sm-6 text-right">
            <div>
              <img src="/assets/images/elements/user.svg" width="64px" height="64px"/>
              <h2>User Management</h2>
              <p className="highlight-p">
                Assign <u>editor</u> role to the right users only
              </p>
              <p className="highlight-p">
                Provide SSO, access control and many other great features for enterprise-friendly workflow
              </p>              
            </div>
          </div>
        </div>
      </div>
    </section>

  </Layout>
)

export default ProductPage
